import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { media } from 'utils/mediaQuery';

const Cover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.color || `rgb(var(--brand-black))`};
  z-index: 1;
  opacity: 0;
  transition: opacity .2s ease;
  pointer-events: none;
`

const CustomImage = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;

  & img {
    width: 100%;
    height: auto;
  }
`

const DotWrapper = styled.div`
  position: absolute;
  top: -16px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity .2s ease;
`

const Dot = styled.div`
  position: relative;
  width: 6px;
  height: 6px;
  background: rgb(var(--brand-brand));
  border-radius: 50%;
`

const ImageWrapper = styled.li`
  position: relative;
  width: 64px;
  height: 80px;

  &:active ${Cover}{
    opacity: 0.4;
  }

  ${media.small`
    width: 40px;
    height: 50px;
    &:hover ${Cover}{
     opacity: 0.4;
    }

    &:hover ${DotWrapper}{
      opacity: 1;
    }
  `}
  

  &:not(:last-child) {
    margin: 0 2px 0 0;
  }
`
const ProjectLineThumb = ({image, ...rest}) => {
  const imageObject = image.asset ? getImage(image.asset) : null;
  return (
    <ImageWrapper {...rest}>
      <DotWrapper><Dot /></DotWrapper>
      <Cover color={imageObject && imageObject.backgroundColor}/>
       <CustomImage 
          image={imageObject}
          alt={image.alt}
          sizes='(max-width: 800px) 60px, (min-width: 801px) 40px'
          objectFit="cover"
          style={{width: "100%", height: "100%"}}
          loading={"eager"}
          objectPosition="center"
          layout="fullWidth" /> 
    </ImageWrapper>
  )
}

export default ProjectLineThumb;