import React, { useEffect } from 'react';
import styled from 'styled-components';
import useMeasure from 'react-use-measure';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import MenuItem from 'components/MenuItem/MenuItem';
import ProjectLineThumb from 'components/ProjectLineThumb/ProjectLineThumb';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
`

const Images = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
`

const ProjectLine = ({node, updateWidth, id, executeSelect, ...rest}) => {
  const [ref, bounds] = useMeasure();
  const [refHeader, boundsHeader] = useMeasure();

  useEffect(() => {
    if(updateWidth && bounds && boundsHeader) {
      updateWidth(bounds, boundsHeader)
    }
  }, [bounds, boundsHeader])

  const onImageClick = (index) => {
    if(executeSelect) {
      executeSelect(id,index)
    }
  }

  return (
    <Wrapper ref={ref} {...rest}>
      <Header ref={refHeader}>
        <MenuItem num={node.subtitle}>{node.title}</MenuItem>
      </Header>
      <Images>
        {node && node.images && node.images.map((image, i) => {
          return (
            <ProjectLineThumb image={image} key={i} onClick={()=>onImageClick(i)} />
          )
        })}
        
      </Images>
    </Wrapper>
  )
}

export default ProjectLine;
