import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "components/graphql-error-list";
import {
  mapEdgesToNodes,
} from "../lib/helpers";
import Platform from "sections/Platform/Platform";
import SEO from "components/seo";
import Layout from "containers/layout";

export const query = graphql`
  query PlatformPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    thumbnails: allSanityProject(sort: {order: ASC, fields: orderRank}) {
      edges {
        node {
          title
          subtitle
          images {
            alt
            asset {
              id
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                layout: FIXED
                outputPixelDensities: [1,2,3]
                width: 40
              )
            }
          }
          id
        }
      }
    }
    projects: allSanityProject(sort: {order: ASC, fields: orderRank}) {
      edges {
        node {
          title
          subtitle
          images {
            alt
            asset {
              id
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          id
        }
      }
    }
  }
`;

const PlatformPage = props => {
  const { data, errors, location } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects) : [];

  const thumbnailNodes = (data || {}).thumbnails
    ? mapEdgesToNodes(data.thumbnails) : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout omitFooter location={location}>
      <SEO title={"Projects"} description={site.description} keywords={site.keywords} />
      <Platform nodes={projectNodes} thumbnails={thumbnailNodes}/>
    </Layout>
  );
};

export default PlatformPage;
