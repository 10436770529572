import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Monorail from 'modules/Monorail/Monorail';
import { media } from 'utils/mediaQuery';


const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc((var(--vh,1vh) * 100) - 56px);
  overflow: hidden;

  ${media.small`
    height: 100vh;
  `}
`

const Stage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
`

const Controls = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
`

const Button = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  cursor: pointer;
`

const StageInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 20px 20px 60px;

  ${media.small`
    padding: 94px 0 102px;
  `}

`

const Track = styled.div`
  width: 100%;
`

const CustomImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
  user-select: none;
  pointer-events: none;
`

const Platform = ({nodes,thumbnails}) => {
  const [ Image, setImage ] = useState(null);
  const PrevRef = useRef();
  const NextRef = useRef();

  

  const onSelected = (data) => {
    
    if(!data) return;

    let project = data.project !== null ? nodes[data.project] : null;
    let image = project && project.images[data.image] ? project.images[data.image] : null;

    if(image) {
      const imageObject = image.asset ? getImage(image.asset) : null;
      setImage({ image: image, imageObject: imageObject, id: image.asset.id });
    } else {
      //setImage(null)
    }
    
  }

  return (
    <Wrapper>
      <Stage>
        <Controls>
          <Button ref={PrevRef}/>
          <Button ref={NextRef}/>
        </Controls>
        <StageInner>
        { Image && <CustomImage 
          key={Image.id}
          src={Image.imageObject.images.fallback.src}
          srcSet={Image.imageObject.images.fallback.srcSet}
          sizes='(max-width: 800px) 400px, (max-width: 1200px) 500px, (max-width: 1920px) 800px, (max-width: 2520px) 1100px, (min-width: 2521px) 1600px'
          alt={Image.image.alt}/> }
        </StageInner>
      </Stage>
      <Track>
        <Monorail nodes={thumbnails} next={NextRef.current} prev={PrevRef.current} selected={onSelected}/>
      </Track>
    </Wrapper>
  )
}

export default Platform;