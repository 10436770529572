import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSprings, to as interpolate, animated, config } from '@react-spring/web';
import { scale, dist } from 'vec-la';
import { media } from 'utils/mediaQuery';
import useMatchMedia from 'utils/matchMedia';
import { useGesture, useWheel } from '@use-gesture/react';
import ProjectLine from 'modules/ProjectLine/ProjectLine';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 2px;
  visibility: ${props => props.show ? "visible" : "hidden"};

  &:active {
    cursor: grab;
  }
`

const DotWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 16px;
  left: 0;
  top: -16px; 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
`

const Dot = styled.div`
  position: relative;
  width: 6px;
  height: 6px;
  background: rgb(var(--brand-brand));
  border-radius: 50%;
`

const Train = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
  user-select: none;
  touch-action: none;

  ${media.small`
    height: 50px;
  `}
`

const LineWrapper = styled(animated.div)`
  position: absolute;
`



const Monorail = ({nodes,selected,prev,next}) => {
  const isDesktop = useMatchMedia('(min-width:768px)', true)
  const [ Show, setShow ] = useState(false);
  const SelectedRef = useRef({project: 0, image: 0});
  const FirstProject = useRef({project: 0, image: 0})
  const LastProject = useRef({project: nodes.length - 1, image: nodes[nodes.length-1].images.length - 1})
  const [ Selected, setSelected ] = useState(SelectedRef.current);
  const isDrag = useRef(false);
  const [ Positions, setPositions ] = useState(); 
  const WidthsArray = useRef([]);
  const PositionsArray = useRef();

  
  useEffect(() => {
    if(selected) {
      selected(Selected)
    }
  },[Selected])

  useEffect(() => {

    const prevThumb = () => {
      const project = nodes[SelectedRef.current.project];
      const prevProject = nodes[SelectedRef.current.project-1];
      const prev = SelectedRef.current.image - 1;
  
      if(project.images[prev]) {
  
        SelectedRef.current = {
          project: SelectedRef.current.project,
          image: prev
        }
        executeSnap();
      } else if(prevProject) {
        SelectedRef.current = {
          project: SelectedRef.current.project-1,
          image: prevProject.images.length - 1
        }
        executeSnap();
      }
    }
  
    const nextThumb = () => {
      const project = nodes[SelectedRef.current.project];
      const nextProject = nodes[SelectedRef.current.project+1];
      const next = SelectedRef.current.image + 1;
  
      if(project.images[next]) {
  
        SelectedRef.current = {
          project: SelectedRef.current.project,
          image: next
        }
        executeSnap();
      } else if(nextProject) {
        SelectedRef.current = {
          project: SelectedRef.current.project+1,
          image: 0
        }
        executeSnap();
      }
    }

    const handleKeypress = (e) => {
      if(e.code === "ArrowLeft") {
        prevThumb()
      } else if(e.code === "ArrowRight") {
        nextThumb()
      }
    }

    window.addEventListener("keydown", handleKeypress)

    if(next) {
      next.addEventListener("click", nextThumb)
    }

    if(prev) {
      prev.addEventListener("click", prevThumb)
    }
    
    return () => {
      window.removeEventListener("keydown", handleKeypress)
      if(next) {
        next.removeEventListener("click", nextThumb)
      }
      
      if(prev) {
        prev.removeEventListener("click", prevThumb)
      }
      
    }
  }, [next,prev, Positions])


  const getOffset = (p,i) => {
    const thumbSize = isDesktop ? 42 : 66;
    const selectedProj = p;
    const selectedImage = i;
    const projPos = Positions[selectedProj];
    const viewportCenterX = window.innerWidth / 2;
    const headerWidth = WidthsArray.current[selectedProj].header;

    return (viewportCenterX - projPos) - headerWidth - ((selectedImage * thumbSize) + thumbSize/2)
  }

  const [props, api] = useSprings(nodes.length, (i) => {
    const ox = Positions ? getOffset(SelectedRef.current.project,SelectedRef.current.image) : 0;
    const to = (i) => ({
      x: (Positions && Positions[i] ? Positions[i] : 0) + ox
    })
    
    return ({
      ...to(i),
      immediate: true,
      onRest: () => {
        if(Positions && Positions.length > 0 && !Show) {
          setShow(true)
        } 
      },
      onChange: (item) => {
        const thumbSize = isDesktop ? 42 : 66;
        if(WidthsArray.current.length > 0) {
          const viewportCenterX = window.innerWidth / 2;
          const headerWidth = WidthsArray.current[i].header;

          if ((i === 0) && (item.value.x + headerWidth) > viewportCenterX) {
            SelectedRef.current = FirstProject.current;
            setSelected(SelectedRef.current)
            const ox = getOffset(FirstProject.current.project,FirstProject.current.image);
            api.start(i => {

              return {
                x: (Positions && Positions[i] ? Positions[i] : 0) + ox,
                immediate: true
              }
            })
            return;
          }

          if ((i === nodes.length - 1) &&  item.value.x + WidthsArray.current[i].main < viewportCenterX) {
            SelectedRef.current = LastProject.current;
            setSelected(SelectedRef.current)
            const ox = getOffset(LastProject.current.project,LastProject.current.image);
            api.start(i => {

              return {
                x: (Positions && Positions[i] ? Positions[i] : 0) + ox,
                immediate: true
              }
            })

            return;
          }

          //console.log(viewportCenterX)
          //console.log(`index: ${i}, xValue: ${item.value.x}, endXValue: ${item.value.x + WidthsArray.current[i]}`)

          //Get which project is currently aligned with the viewport center
          if(item.value.x <= viewportCenterX && viewportCenterX <= item.value.x + WidthsArray.current[i].main) {
            const project = nodes[i];
            const imageAmt = project.images.length;
            
            const x = viewportCenterX - item.value.x;
            let tempObj;

            if(x >= headerWidth) {
              const relativeThumbsX = x - headerWidth;
              const imageIndex = Math.floor(relativeThumbsX / thumbSize);  
              tempObj = {
                project: i,
                image: imageIndex
              }

              if(tempObj.project !== SelectedRef.current.project || tempObj.image !== SelectedRef.current.image) {
                SelectedRef.current = tempObj;
                setSelected(SelectedRef.current)
              }
              
            } else {
              tempObj = {
                project: i,
                image: null
              }

              if(tempObj.project !== SelectedRef.current.project || tempObj.image !== SelectedRef.current.image) {
                SelectedRef.current = tempObj;
                setSelected(SelectedRef.current)
              }
            }
          }
        }
        
      }
    })

  }, [Positions, isDesktop]) 
  

  if (typeof document !== `undefined`) {
    useWheel(({ event, xy, previous, movement: pos, offset: [ox,oy], velocity, direction, down, memo })=> {
      
      const maxThresh = getOffset(nodes.length - 1, nodes[nodes.length-1].images.length - 1);
      const minThresh = getOffset(0,0);
      const xDiff = memo ? ox - memo[0] : 0;
      const yDiff = memo ? oy - memo[1] : 0;
      let offsetVal;

      if(direction[1] !== 0 && direction[0] === 0) {
        if(memo) {
          offsetVal = memo[2] + yDiff
        } else {
          offsetVal = oy + yDiff;
        }
      } else if (direction[1] !== 0 && direction[0] !== 0){
        if(memo) {
          offsetVal = memo[2] + (xDiff + yDiff) / 2;
        } else {
          offsetVal = oy + xDiff;
        }
      } else {
        if(memo) {
          offsetVal = memo[2] + xDiff;
        } else {
          offsetVal = oy + xDiff;
        }
      }
      console.log(`xDiff: ${xDiff}`);
      console.log(`ox: ${ox}, oy: ${oy}`);
   
      

      if(offsetVal <= maxThresh) {
        offsetVal = maxThresh
      } else if(offsetVal >= minThresh) {
        offsetVal = minThresh
      }
      memo = [ox, oy, offsetVal]
      api.start(i => {
  
        return {
          x: (Positions && Positions[i] ? Positions[i] : 0) + offsetVal,
          onRest: (item) => {
            if(i === SelectedRef.current.project) {
              executeSnap();
            }
            
          },
          config: config.slow
        }
      })
     
      return memo;
    }, { from: () => [ 0, props[0].x.get()], target: document })
  }
  
  const bindDrag = useGesture(
    {
      onDrag: ({ event, xy, previous, down, movement: pos, offset: [ox], velocity, direction }) => {
        if(!down && velocity[0] === 0) {
          executeSnap()
          isDrag.current = false;

          return;
        }
        const maxThresh = getOffset(nodes.length - 1, nodes[nodes.length-1].images.length - 1);
        const minThresh = getOffset(0,0);

        if(down) {
          isDrag.current = true;
        }

        if(ox <= maxThresh) {
          ox = maxThresh
        } else if(ox >= minThresh) {
          ox = minThresh
        }

        api.start(i => {
          return {
            x: (Positions && Positions[i] ? Positions[i] : 0) + ox,
            immediate: down,
            onRest: (item) => {
              if(!down && i === SelectedRef.current.project) {
                executeSnap();
              }
              
            },
            config: { 
              velocity: ox === minThresh || ox === maxThresh ?  0 : velocity[0] * direction[0],
              decay: 0.98,
            }
          }
        })
      },
    }, {
      drag: { from: () => [ props[0].x.get(), 0] }
    }
  )

  const thumbClicked = (p,i) => {
    if(!isDrag.current) {
      SelectedRef.current = {
        project: p,
        image: i
      }
  
      executeSnap();
    }
  }

  

  const executeSnap = () => {
    console.log(Positions);
    const ox = getOffset(SelectedRef.current.project,SelectedRef.current.image);
    api.start(i => {

      return {
        x: (Positions && Positions[i] ? Positions[i] : 0) + ox,
        config: config.default
      }
    })

  }

  return (
    <Wrapper show={Show}>
      <DotWrapper>
        <Dot />
      </DotWrapper>
      <Train {...bindDrag()}>
        {props.map(({ x }, i) => { 
          const lineNode = nodes[i];
          return (
            <LineWrapper style={{
              transform: interpolate(
                [x],
                (x) => `translate3d(${x}px,0,0)`
              ),
            }}>
              <ProjectLine id={i} node={lineNode} executeSelect={thumbClicked} updateWidth={(bounds,boundsHeader) => {
                  if(bounds.width > 0 && boundsHeader.width > 0) {
                    WidthsArray.current[i] = { main: bounds.width, header: boundsHeader.width };
                  }
                  
                  if(WidthsArray.current.length === props.length) {
                    PositionsArray.current = WidthsArray.current.map((width, widthIndex) => {
                      
                      if(widthIndex > 0) {
                        let initialValue = 0;
                        const position = WidthsArray.current.reduce((acc,curr, currentIndex) => {
                          if(currentIndex < widthIndex) {
                            return acc + curr.main
                          }

                          return acc;
                        }, initialValue)

                        return position;
                      } else {
                        return 0;
                      }
                    })
                    setPositions(PositionsArray.current);
                  }
              }}/>
            </LineWrapper> 
          )
        })}
      </Train>
    </Wrapper>
  )
}

export default Monorail;
